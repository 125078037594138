import { Component, effect, input, signal } from '@angular/core';
import * as fromSeriousSystem from '@serious-system';
import {
  NgxExtendedPdfViewerModule,
  PdfSidebarView,
} from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'squadbox-document-viewer-content',
  imports: [NgxExtendedPdfViewerModule, fromSeriousSystem.SpinnerDirective],
  template: `
    <!-- Spinner -->
    <div
      class="absolute inset-0 z-10 flex items-center justify-center"
      [class.hidden]="!isLoading()"
    >
      <div sdSpinner size="md"></div>
    </div>
    <!-- PDF Viewer -->
    <ngx-extended-pdf-viewer
      [src]="src()"
      [enableDragAndDrop]="false"
      [showToolbar]="false"
      [sidebarVisible]="true"
      [activeSidebarView]="PdfSidebarView.THUMBS"
      [showBorders]="false"
      [customSidebar]="customSidebar"
      (pdfLoadingStarts)="handlePdfLoadingStarts()"
      (pdfLoaded)="handlePdfLoaded()"
    ></ngx-extended-pdf-viewer>
    <!-- PDF Viewer: Sidebar -->
    <ng-template #customSidebar>
      <pdf-sidebar-content
        id="sidebarContainer"
        [customThumbnail]="customThumbnail"
        [hideSidebarToolbar]="true"
      ></pdf-sidebar-content>
    </ng-template>
    <!-- PDF Viewer: Thumbnail -->
    <ng-template #customThumbnail>
      <a class="pdf-viewer-template">
        <div class="thumbnail" data-page-number="PAGE_NUMBER">
          <div class="image-container">
            <img class="thumbnailImage" />
          </div>
          <div class="page-number">PAGE_NUMBER</div>
        </div>
      </a>
    </ng-template>
  `,
  styles: [
    `
      :host {
        @apply relative w-full h-full;
        @apply typo-h1 bg-neutral-100;
        @apply flex items-center justify-center;

        ::ng-deep {
          ngx-extended-pdf-viewer {
            @apply w-full h-full;

            #mainContainer,
            #sidebarContainer,
            #sidebarContent,
            #viewerContainer {
              @apply bg-neutral-100 !important;
            }

            #thumbnailView,
            #viewerContainer {
              @apply hide-scrollbar !important;
            }

            #sidebarContainer {
              #thumbnailView {
                @apply mb-28 !important; // Fix for last thumbnail clipping
                .thumbnail {
                  @apply border-none mb-11 !important;
                  &.selected {
                    img {
                      @apply border-0 border-b-4 border-primary-500 !important;
                    }
                    .page-number {
                      @apply font-semibold !important;
                    }
                  }
                  .page-number {
                    @apply w-full mt-4 text-center !important;
                    @apply typo-caption text-neutral-700 !important;
                  }
                }
              }
            }

            #viewerContainer .page {
              @apply first:mt-11;
            }
          }
        }
      }
    `,
  ],
})
export class DocumentViewerContentComponent {
  public readonly src = input.required<string>();

  public readonly isLoading = signal(true);
  public readonly PdfSidebarView = PdfSidebarView;

  constructor() {
    effect(() => {
      if (this.src()) {
        this.isLoading.set(true);
      }
    });
  }

  public handlePdfLoaded() {
    this.isLoading.set(false);
  }

  public handlePdfLoadingStarts() {
    this.isLoading.set(true);
  }
}
