import { Component, input, output } from '@angular/core';
import * as fromSeriousSystem from '@serious-system';

@Component({
  selector: 'squadbox-document-viewer-header',
  imports: [
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.UseFileTypeIconDirective,
    fromSeriousSystem.UseUIBasicIconDirective,
  ],
  template: `
    <div grid-area="header" class="flex gap-2">
      <svg [sdUseFileTypeIcon]="fileType()" class="size-6"></svg>
      <span class="typo-p1 font-semibold">{{ title() }}</span>
    </div>
    <div grid-area="actions" class="flex">
      <button
        sdIconButton
        variant="icon"
        color="neutral"
        size="sm"
        (click)="closeClicked.emit()"
      >
        <svg sdUseUIBasicIcon="close"></svg>
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        @apply h-16 px-4;
        @apply border-b border-neutral-200;
        @apply grid grid-cols-[auto_min-content] gap-4 items-center;
        grid-template-areas: 'header actions';
      }
    `,
  ],
})
export class DocumentViewerHeaderComponent {
  public readonly title = input<string>('Document 1');
  public readonly fileType = input<fromSeriousSystem.FileTypesIconsType>('pdf');
  public readonly closeClicked = output<void>();
}
