import { createActionGroup, emptyProps, props } from '@ngrx/store';

import * as fromGenerated from '../../_generated';

export interface SelectDocumentInChatMessagePayload {
  chatMessageId: number;
  documentUuid: string;
}

export const ChatModalActions = createActionGroup({
  source: 'ChatModal',
  events: {
    'Open Delete Chat Modal': emptyProps(),
    'Close Delete Chat Modal': emptyProps(),
  },
});

export const ChatActions = createActionGroup({
  source: 'Chat',
  events: {
    'Select Chat': props<{
      id: number;
    }>(),
    'Unselect Chat': emptyProps(),
    'Toggle Loading': props<{
      isLoading: boolean;
    }>(),
    'Select Empty Chat': emptyProps(),
    'Load Empty Chat': emptyProps(),
    'Load Empty Chat Success': props<{ chat: fromGenerated.ChatView }>(),
    'Load Empty Chat Failure': props<{ error: string }>(),
    'Open Side Container': emptyProps(),
    'Close Side Container': emptyProps(),
    'Open Threads Listing Container': emptyProps(),
    'Close Threads Listing Container': emptyProps(),
  },
});

export const ChatsActions = createActionGroup({
  source: 'Chats',
  events: {
    'Load Chats': emptyProps(),
    'Load Chats Success': props<{ chats: fromGenerated.ChatView[] }>(),
    'Load Chats Failure': props<{ error: string }>(),
    'Delete Chat': props<{
      id: number;
    }>(),
    'Delete Chat Success': props<{ id: number }>(),
    'Delete Chat Failure': props<{ error: string }>(),
  },
});

export const ChatWithMessagesActions = createActionGroup({
  source: 'ChatWithMessages',
  events: {
    'Load ChatWithMessages': props<{
      id: number;
    }>(),
    'Load ChatWithMessages Success': props<{
      chatWithMessages: fromGenerated.ChatWithMessagesView;
    }>(),
    'Load ChatWithMessages Failure': props<{ error: string }>(),
    'Add ChatMessage': props<{
      content: string;
      assistant?: { uuid: string; description: string };
    }>(),
    'Add ChatMessage Success': emptyProps(),
    'Add ChatMessage Failure': props<{ error: string }>(),
    'Received ChatWithMessages from Socket Success': props<{
      chatWithMessages: fromGenerated.ChatWithMessagesView;
    }>(),
    'Select Document in ChatMessage':
      props<SelectDocumentInChatMessagePayload>(),
    'Unselect Document in ChatMessage': emptyProps(),
  },
});

export const ChatsSocketActions = createActionGroup({
  source: 'ChatsSocket',
  events: {
    Reconnect: emptyProps(),
  },
});
