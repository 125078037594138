import { Component, computed, input, output } from '@angular/core';
import * as fromSeriousSystem from '@serious-system';
import * as fromGenerated from '../../../_generated';
import * as fromShared from '../../shared';
import { SelectDocumentInChatMessagePayload } from '../../store/chats.actions';
import { MessageComponent } from './message.component';

export interface MessageListingType {
  id: number;
  content: string;
  isFromUser: boolean;
  documentsChunks?: fromGenerated.DocumentChunkView[];
}

@Component({
  selector: 'squadbox-messages-listing',
  imports: [
    MessageComponent,
    fromSeriousSystem.FileChipComponent,
    fromShared.FilenameWithoutExtensionPipe,
  ],
  template: `
    <!-- LOADING MESSAGES -->
    @if (messageCount(); as messageCount) { @if (isLoading(); as isLoading) {
    @for (message of [].constructor(messageCount); track $index; let i = $index)
    {
    <squadbox-message
      [isFromUser]="i % 2 !== 0 ? true : false"
      [isLoading]="true"
      [messageContent]="loadingMessage()"
      class="last:tablet-landscape:pb-10 last:pb-4"
      [class]="{
        'self-end': i % 2 !== 0,
        'self-start': i % 2 === 0
      }"
    />
    } } @else { @for (message of messages(); track message.id; let i = $index) {
    <squadbox-message
      [isFromUser]="message.isFromUser"
      [messageContent]="message.content"
      [documentsChunks]="message.documentsChunks"
      class="last:tablet-landscape:pb-10 last:pb-4"
      [class]="{
        'self-end': message.isFromUser,
        'self-start': !message.isFromUser
      }"
      [isMobile]="isMobile()"
      (documentClicked)="
        handleMessageDocumentClicked({
          chatMessageId: message.id,
          documentUuid: $event.documentUuid
        })
      "
    />
    } } }

    <!-- AI IS THINKING 🧠 -->
    @if (isThinking()) {
    <squadbox-message
      [messageContent]="''"
      [isFromUser]="false"
      [isThinking]="true"
      class="self-start tablet-landscape:pb-10 pb-4"
    />
    }
  `,
  styles: [
    `
      :host {
        @apply flex flex-col gap-4 w-full tablet-landscape:max-w-180 p-4 tablet-landscape:py-4 tablet-landscape:p-0;
      }
    `,
  ],
})
export class MessagesListingComponent {
  public readonly messages = input.required<MessageListingType[]>();
  public readonly isLoading = input.required<boolean>();
  public readonly isThinking = input.required<boolean>();
  public readonly isMobile = input.required<boolean>();
  public readonly messageCount = input.required<number>();
  public readonly documentInChatMessageClicked =
    output<SelectDocumentInChatMessagePayload>();

  public readonly loadingMessage = computed(() => {
    const message = 'Lorem ipsum dolor sit amat consectetur.';
    return this.isMobile() ? message : `${message}\n${message}`;
  });

  public handleMessageDocumentClicked(
    event: SelectDocumentInChatMessagePayload
  ) {
    this.documentInChatMessageClicked.emit(event);
  }
}
